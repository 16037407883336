import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../common/constants';
import {Observable} from 'rxjs';
import {Plan} from '../model/plan';

@Injectable({
  providedIn: 'root'
})
export class PlanService {
    private url: string;

    constructor(private http: HttpClient) {
        this.url = API_URL.concat('/plan');
    }

    public getAll(): Observable<Plan[]> {
        return this.http.get<Plan[]>(this.url + '/');
    }

    findById(id: number): Observable<Plan> {
        return this.http.get<Plan>(this.url + '/' + id);
    }
}
