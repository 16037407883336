import { Injectable } from '@angular/core';
import {API_URL} from '../common/constants';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
const httpOptions = {
    headers: new HttpHeaders({responseType: 'text'})
};

@Injectable({
    providedIn: 'root'
})

export class UploadFileService {
    private uploadUrl: string;

    constructor(private http: HttpClient) {
        this.uploadUrl = API_URL.concat('/files/');
    }

    public saveDishImage(file: File, id: string): Observable<any>{
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(this.uploadUrl + 'dish/' + id, formData, httpOptions);
    }

    public saveRestaurantImage(file: File, id: string): Observable<any>{
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post(this.uploadUrl + 'restaurant/' + id, formData, httpOptions);
    }
}
