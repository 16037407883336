import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_URL} from '../common/constants';
import {Observable} from 'rxjs';
import {User} from '../model/user';
import {Dish} from '../model/dish';

@Injectable({
  providedIn: 'root'
})
export class UserService {

    private url: string;

    constructor(private http: HttpClient) {
        this.url = API_URL.concat('/user');
    }

    public get(): Observable<User> {
        return this.http.get<User>(this.url + '/');
    }

    public getDetails(): Observable<any> {
        return this.http.get<any>(this.url + '/details');
    }

    public save(user: User): Observable<any> {
        return this.http.post<User>(this.url + '/save', user);
    }
}
